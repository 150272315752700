import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from 'components/Image';

import classes from './BackgroundImage.scss';

function BackgroundImage({
  className: classNameProp,
  classNameImage,
  image,
  alt,
  children,
}) {
  const className = classNames(classNameProp, classes.root);

  return (
    <div className={className}>
      <Image
        className={classNames(classNameImage, classes.image)}
        image={image}
        alt={alt}
      />

      {children}
    </div>
  );
}

BackgroundImage.propTypes = {
  className: PropTypes.string,
  classNameImage: PropTypes.string,
  alt: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node,
};

BackgroundImage.defaultProps = {
  className: undefined,
  classNameImage: undefined,
  children: null,
};

export default BackgroundImage;
