import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from 'components/Image';

import classes from './Cta.scss';

function Cta({ image, alt, contact, children }) {
  const className = classNames({
    [classes.root]: true,
    [classes.contact]: contact,
  });

  return (
    <div className={className}>
      {image && (
        <div className={classes.wrapper}>
          <Image className={classes.image} image={image} alt={alt} />
        </div>
      )}

      <div className={classes.content}>{children}</div>
    </div>
  );
}

Cta.propTypes = {
  image: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  alt: PropTypes.string,
  contact: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Cta.defaultProps = {
  image: null,
  alt: null,
  contact: false,
};

export default Cta;
