import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Container from 'components/Container';
import Text from 'components/Text';
import Heading from 'components/Heading';
import BackgroundImage from 'components/BackgroundImage';

import classes from './Title.scss';

function Title({ subtitle, title, image, alt, onlyImage, spacing, children }) {
  const className = classNames({
    [classes.root]: true,
    [classes['only-image']]: onlyImage,
    [classes.spacing]: spacing,
  });

  return (
    <div className={className}>
      <Container size="large">
        <BackgroundImage classNameImage={classes.image} image={image} alt={alt}>
          <div className={classes.title}>
            <div className={classes.wrapper}>
              {!onlyImage && (
                <div className={classes.content}>
                  <Text weight="semi" color="primary-lighter">
                    {subtitle}
                  </Text>

                  <Heading size="xxl" as="h1" color="white" spacing="xs">
                    {title}
                  </Heading>

                  <Text color="white">{children}</Text>
                </div>
              )}
            </div>
          </div>
        </BackgroundImage>
      </Container>
    </div>
  );
}

Title.propTypes = {
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  children: PropTypes.string,
  alt: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onlyImage: PropTypes.bool,
  spacing: PropTypes.bool,
};

Title.defaultProps = {
  subtitle: null,
  title: null,
  children: null,
  onlyImage: false,
  spacing: false,
};

export default Title;
