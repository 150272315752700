import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Grid.scss';

function Grid({ gap, width, children: childrenProp }) {
  const children = childrenProp.length ? childrenProp.flat() : [childrenProp];

  const className = classNames({
    [classes.root]: true,
    [classes[`width-${width}`]]: true,
    [classes.gap]: gap,
  });

  return (
    <div className={className}>
      {children.map((child, key) => (
        <div className={classes.item} key={key}>
          {child}
        </div>
      ))}
    </div>
  );
}

Grid.propTypes = {
  gap: PropTypes.bool,
  width: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Grid.defaultProps = {
  gap: true,
  width: 3,
};

export default Grid;
