import React from 'react';
import PropTypes from 'prop-types';

import classes from './Usp.scss';

function Usp({ icon, title, children }) {
  return (
    <div className={classes.root}>
      <img src={icon} alt={title} className={classes.image} />

      {children}
    </div>
  );
}

Usp.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Usp;
