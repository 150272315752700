import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from 'components/Heading';
import BackgroundImage from 'components/BackgroundImage';
import TransLink from 'components/TransLink';

import classes from './ImageBlock.scss';

function ImageBlock({
  className: classNameProp,
  title,
  noTitleMargin,
  image,
  to,
  overlay,
  children: childrenProp,
}) {
  const [hover, setHover] = useState(false);
  const children = childrenProp.length ? childrenProp : [childrenProp];

  const handleEnter = () => setHover(true);
  const handleLeave = () => setHover(false);

  const Component = to ? TransLink : 'span';

  return (
    <Component
      className={classNames(classNameProp, classes.root)}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      to={to}
    >
      <BackgroundImage image={image} alt={title}>
        <div
          className={classNames({
            [classes.content]: true,
            [classes.overlay]: overlay,
          })}
        >
          <Heading color="white" noMargin={noTitleMargin}>
            {title}
          </Heading>

          {children.map((child, key) => {
            if (child.type.name === 'Button') {
              return cloneElement(child, { key, to, hover });
            }

            return child;
          })}
        </div>
      </BackgroundImage>
    </Component>
  );
}

ImageBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  noTitleMargin: PropTypes.bool,
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  to: PropTypes.string,
  overlay: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ImageBlock.defaultProps = {
  className: null,
  noTitleMargin: false,
  to: null,
  overlay: true,
};

export default ImageBlock;
