import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './ButtonGroup.scss';

function ButtonGroup({ centerMobile, children }) {
  const className = classNames({
    [classes.root]: true,
    [classes['center-mobile']]: centerMobile,
  });

  return <div className={className}>{children}</div>;
}

ButtonGroup.propTypes = {
  centerMobile: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ButtonGroup.defaultProps = {
  centerMobile: false,
};

export default ButtonGroup;
