import React from 'react';

import jankoImage from 'assets/images/team/janko.png';

import Content from 'blocks/Content';

import Section from 'components/Section';
import Cta from 'components/Cta';
import Heading from 'components/Heading';
import Text from 'components/Text';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';

import config from '../../config';

function ContactCta() {
  return (
    <Section>
      <Content wide>
        <Cta
          contact
          image={{ src: jankoImage }}
          alt="Samen een kop koffie drinken?"
        >
          <Heading size="xl" spacing="xs">
            Samen een kop koffie drinken?
          </Heading>

          <Text color="gray-dark" spacing="lg">
            Bel of mail mij om jouw plannen te bespreken en een goede kop vers
            gemalen bonen koffie te komen drinken! Altijd vrijblijvend.
          </Text>

          <ButtonGroup centerMobile>
            <Button
              as="a"
              color="tertiary"
              href={`tel:${config.company.phone}`}
              icon="phone"
            >
              {config.company.phone}
            </Button>

            <Button
              as="a"
              color="primary"
              outline
              href={`mailto:${config.company.email}`}
              icon="mail"
            >
              {config.company.email}
            </Button>
          </ButtonGroup>
        </Cta>
      </Content>
    </Section>
  );
}

export default ContactCta;
