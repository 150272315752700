import React from 'react';

import dienstenImage from 'assets/images/pages/diensten/1.jpg';
import b2bImage1 from 'assets/images/pages/ecommerce_b2becommerce/1.jpg';
import b2cImage1 from 'assets/images/pages/ecommerce_b2cecommerce/1.jpg';

import gearsIcon from 'assets/images/icons/gears.svg';
import megafonIcon from 'assets/images/icons/megafon.svg';
import pencilIcon from 'assets/images/icons/pencil.svg';
import puzzleIcon from 'assets/images/icons/puzzle.svg';
import serverIcon from 'assets/images/icons/server.svg';
import slidersIcon from 'assets/images/icons/sliders.svg';
import wrenchIcon from 'assets/images/icons/wrench.svg';
import rocketIcon from 'assets/images/icons/rocket.svg';
import codeIcon from 'assets/images/icons/code.svg';
import webshopIcon from 'assets/images/icons/webshop.svg';

import Title from 'blocks/Title';
import Content from 'blocks/Content';
import Grid from 'blocks/Grid';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import ImageBlock from 'components/ImageBlock';
import Text from 'components/Text';
import Button from 'components/Button';
import Usp from 'components/Usp';

const usp = [
  {
    title: 'Webshop ontwerp',
    info:
      'Webshops of kunstwerken? Onze designers zorgen ervoor dat een ontwerp converteert en de juiste waarden uitstraalt.',
    to: '/diensten/webshop-ontwerp',
    icon: webshopIcon,
  },
  {
    to: '/diensten/webshop-ontwerp/grafish-design',
    title: 'Grafisch design',
    info:
      "Jouw bedrijf perfect in beeld? Wij zorgen ervoor dat beeldmateriaal zoals logo's, ontwerp en huisstijl passen bij jouw merk.",
    icon: pencilIcon,
  },
  {
    to: '/diensten/webshop-ontwerp/frontend-design',
    title: 'Front-end design',
    info:
      'Het ontwerp perfect vertaald naar het scherm van jouw klanten. Uiteraard altijd rekening houdend met de User eXperience.',
    icon: slidersIcon,
  },
  {
    to: '/diensten/webshop-bouwen',
    title: 'Webshop bouwen',
    info:
      'Onze webbouwers weten iedere puzzel op te lossen. Jarenlange ervaring zorgt ervoor dat jouw E-commerce platform staat.',
    icon: gearsIcon,
  },
  {
    to: '/diensten/webshop-bouwen/development',
    title: 'Development',
    info:
      'Development zit ons in het bloed. Geen uitdaging te gek, geen opdracht te uitdagend. Wij kijken uit naar een mooie samenwerking.',
    icon: codeIcon,
  },
  {
    to: '/diensten/webshop-bouwen/maatwerk',
    title: 'Maatwerk oplossingen',
    info:
      'Een webshop die qua uiterlijk en functionaliteit volledig is aangepast aan de behoeften van jouw organisatie. Dat is Batao.',
    icon: puzzleIcon,
  },
  {
    to: '/diensten/webshop-bouwen/magento-webshop',
    title: 'Magento webshop',
    info:
      'Wij kennen zowel Magento 1 als Magento 2 door en door dankzij onze jarenlange ervaring en bijbehorende certificeringen. ',
    icon: rocketIcon,
  },
  {
    to: '/diensten/webshop-onderhoud',
    title: 'Webshop onderhoud',
    info:
      'Wij houden de prestaties van je webshop goed in de gaten, sturen bij waar nodig en adviseren we over nieuwe kansen.',
    icon: wrenchIcon,
  },
  {
    to: '/diensten/webshop-onderhoud/magento-hosting',
    title: 'Magento Hosting',
    info:
      'We vinden het superbelangrijk dat jouw webwinkel stabiel, snel en betrouwbaar is. 1 seconden vertraging is 10% minder winst!',
    icon: serverIcon,
  },
  {
    to: '/diensten/webshop-onderhoud/online-marketing',
    title: 'Online Marketing',
    info:
      'Of het nu gaat om SEO, SEA of social media: wij kunnen je adviseren, opleiden of je het werk uit handen nemen.',
    icon: megafonIcon,
  },
];

const Diensten = () => (
  <Layout>
    <Seo image={{ src: dienstenImage }} />

    <Title
      image={{ src: dienstenImage }}
      alt="Jouw webshop een groot succes"
      subtitle="De diensten van Batao"
      title="Jouw webshop een groot succes"
    >
      Samen bouwen aan jouw resultaten. Dit is wat we voor je kunnen doen.
    </Title>

    <Section>
      <Content>
        <Heading size="xl" as="h2">
          Jouw webshop, onze zorg
        </Heading>

        <Text spacing="lg">
          Tof dat je geïnteresseerd bent in onze diensten. Of het nu gaat om een
          B2B of B2C oplossing; bij ons ben je aan het juiste adres. Wij
          ontwerpen webshops, bouwen ze en helpen je bij de online marketing.
          Bekijk hieronder wat we voor je kunnen betekenen, of neem direct
          contact met ons op!
        </Text>

        <Button color="tertiary" to="/contact">
          Ja, ik wil koffie komen drinken
        </Button>
      </Content>

      <Grid width={2}>
        <ImageBlock
          title="B2B E-commerce"
          image={{ src: b2bImage1 }}
          to="/ecommerce/b2b-ecommerce"
        >
          <Text color="white" spacing="lg">
            Handelen met andere ondernemingen? Dat vraagt om een specifieke
            aanpak! Wij hebben veel ervaring met B2B en de industrie-sector.
          </Text>

          <Button as="span" color="white" hoverColor="secondary" outline>
            Meer over B2B E-commerce
          </Button>
        </ImageBlock>

        <ImageBlock
          title="B2C E-commerce"
          image={{ src: b2cImage1 }}
          to="/ecommerce/b2c-ecommerce"
        >
          <Text color="white" spacing="lg">
            Direct verkopen aan de eindgebruiker? Wij zorgen ervoor dat jouw
            producten de deur uitgaan en voelen ons verantwoordelijk voor jouw
            resultaten.
          </Text>

          <Button as="span" color="white" hoverColor="secondary" outline>
            Meer over B2C E-commerce
          </Button>
        </ImageBlock>
      </Grid>
    </Section>

    <Section>
      <Content>
        <Heading size="xl" as="h2">
          Wat wij voor je kunnen betekenen
        </Heading>
      </Content>

      <Grid width={4}>
        {usp.map((item, key) => (
          <Usp key={key} icon={item.icon} title={item.title}>
            <Heading color="primary-light" size="sm">
              {item.title}
            </Heading>

            <Text spacing="xs">{item.info}</Text>

            <Button size="sm" color="secondary" outline to={item.to}>
              Lees verder
            </Button>
          </Usp>
        ))}
      </Grid>
    </Section>

    <ContactCta />
  </Layout>
);

export default Diensten;
